/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useState } from 'react';
import { Button, ImageUploader, Input, SafeArea, Toast } from 'antd-mobile';
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader';
import { CloseOutline } from 'antd-mobile-icons';
import axios from 'axios';

import './index.scss';

import { baseUrl } from '../utils';

const gameSession = '4';

type Props = {
  openId: string;
  orderId: string;
  nickname: string;
  setVisible: (v: boolean) => void;
  getData: () => void;
};

function Capital(props: Props) {
  const { openId, orderId, nickname, setVisible, getData } = props;
  const [addPrice, setAddPrice] = useState('');
  const [removePrice, setRemovePrice] = useState('');
  const [fileList, setFileList] = useState<ImageUploadItem[]>([]);

  const uploadImg = async (file: File): Promise<ImageUploadItem> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res: any = await axios.post(`${baseUrl}/common/uploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const { data = '', code } = res.data || {};
      if (code === 200) {
        return {
          url: data
        };
      } else {
        throw new Error('Fail to upload');
      }
    } catch (e) {
      throw new Error('Fail to upload');
    }
  };
  const submit = () => {
    if (!addPrice && !removePrice) {
      Toast.show({
        icon: 'fail',
        content: '请先输入转入或转出资金'
      });
      return;
    }
    if (!fileList.length) {
      Toast.show({
        icon: 'fail',
        content: '请上传账户截图'
      });
      return;
    }
    const data = {
      openId,
      orderId,
      nickname,
      addPrice: addPrice || 0,
      removePrice: removePrice || 0,
      orderPicVos: fileList,
      picType: 2,
      isFront: 1,
      gameSession
    };
    axios
      .post(`${baseUrl}/sys/umsExplodeOrder/addOutInPrice`, data)
      .then((res: any) => {
        const { code, message } = res?.data || {};
        if (code === 200) {
          Toast.show({
            icon: 'success',
            content: '提交成功！'
          });
          getData();
          setVisible(false);
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(e => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
        console.log(e);
      });
  };

  useEffect(() => {
    if (!orderId) {
      return;
    }
    const params = {
      orderId
    };
    axios
      .get(`${baseUrl}/sys/umsExplodeOrder/getOutInPriceDetail`, { params })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200) {
          const { addPrice, removePrice, orderPicVos = [] } = data;
          setAddPrice(addPrice);
          setRemovePrice(removePrice);
          setFileList(orderPicVos);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);
  return (
    <div className="capital">
      <div className="content">
        <div className="close">
          <CloseOutline onClick={() => setVisible(false)} />
        </div>
        <div className="scroll">
          <div className="row">
            <div className="label">今日转入资金</div>
            <div className="input border">
              <Input
                style={{ height: '100%' }}
                value={addPrice}
                onChange={val => {
                  setAddPrice(val);
                }}
                onBlur={() => {
                  if (isNaN(+addPrice)) {
                    setAddPrice('');
                  }
                }}
              />
            </div>
            <div className="unit">元</div>
          </div>
          <div className="row">
            <div className="label">今日转出资金</div>
            <div className="input border">
              <Input
                style={{ height: '100%' }}
                value={removePrice}
                onChange={val => {
                  setRemovePrice(val);
                }}
                onBlur={() => {
                  if (isNaN(+removePrice)) {
                    setAddPrice('');
                  }
                }}
              />
            </div>
            <div className="unit">元</div>
          </div>
          <div className="desc">注:今日总资产=资金进出后的总资产(融资账户为净资产）</div>

          <div className="uploader">
            <span className="title">账户截图</span>
            <div className="uploader-content">
              <ImageUploader
                style={{ '--cell-size': '140px' }}
                value={fileList}
                onChange={setFileList}
                upload={uploadImg}
                maxCount={1}
              />
            </div>
          </div>
          <div className="btn">
            <Button color="danger" style={{ width: '100%' }} onClick={submit}>
              确认
            </Button>
          </div>
          <div className="remark">除权规则：入金比赛成绩稀释，出金保持成绩不变</div>
          <div>
            <SafeArea position="bottom" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Capital;
