/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';

import './index.scss';

import codeImg from '../assets/code.jpg';
function NoPay() {
  return (
    <div className="no-pay">
      <div className="desc">您未报名，请先报名。</div>
      <div className="desc">有问题联系复利小妹。</div>
      <img src={codeImg} alt="" />
    </div>
  );
}

export default NoPay;
