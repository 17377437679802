/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, Empty, ImageViewer, List, Popover, SpinLoading, Tabs, Toast } from 'antd-mobile';
import { InformationCircleOutline } from 'antd-mobile-icons';
import axios from 'axios';

import './index.scss';

import codeImg from '../../assets/bao.jpg';
import { baseUrl, groupTypeOption } from '../../utils';

type Props = {
  canPreview: boolean;
  openId: string;
  payOpenId: string;
  groupType: string;
  onClose: () => void;
  updateCanPreview: () => void;
};

type User = {
  orderId: string;
  openId: string;
  todayEarningRate: string;
  totalAssets: string;
  totalEarningRate: string;
  gameName: string;
};

type SignType = {
  appId: string;
  timeStamp: string;
  nonceStr: string;
  signType: string;
  paySign: string;
};

const gameSession = '4';
const isRank = location.href.includes('rank=1');

function OrderList(props: Props) {
  const { openId, payOpenId, groupType, canPreview, onClose, updateCanPreview } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [group, groupChange] = useState('101');
  const [tabKey, setTabKey] = useState('total');
  const [imageSrc, setSrc] = useState('');
  const [previewShow, setPreviewShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const userRef = useRef<User>();
  const countRef = useRef(0);

  const curIdx = useMemo(() => {
    return users.findIndex(item => item.openId === openId);
  }, [users, openId]);

  const checkAuthority = (user: User) => {
    if (canPreview) {
      showView(user);
    } else {
      userRef.current = user;
      setSrc(codeImg);
      setPreviewShow(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Dialog.confirm({
        content: '仅需1元，即可解锁当天所有选手报单详情，超值！',
        confirmText: '支付',
        onConfirm: async () => {
          await getPrePayId();
        }
      });
    }
  };

  const getPrePayId = () => {
    return axios
      .post(`${baseUrl}/wechat/pay/getPreviewPayId`, { openId: payOpenId, unionId: openId, gameSession })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200 && data) {
          const { pre_pay_id } = data;
          getSign(pre_pay_id);
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
      })
      .finally(() => {
        console.log('111');
      });
  };

  const getSign = (prepay_id: string) => {
    axios
      .get(`${baseUrl}/wechat/pay/getSingCode`, { params: { prepay_id } })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200 && data) {
          pay(data, prepay_id);
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
      })
      .finally(() => {
        console.log('finally');
      });
  };

  const pay = (data: SignType, prepay_id: string) => {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        ...data,
        package: `prepay_id=${prepay_id}`
      },
      (res: any) => {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          getAndUpdateOrderStatus(prepay_id);
          updateCanPreview();
          setSrc('');
          setPreviewShow(false);
          showView(userRef.current);
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        }
      }
    );
  };

  const getAndUpdateOrderStatus = (prepayId: string) => {
    axios
      .get(`${baseUrl}/wechat/pay/getAndUpdateOrderStatus`, {
        params: { prepayId }
      })
      .catch(e => console.log(e));
  };

  const showView = (user: User | undefined) => {
    if (!user) return;
    const params = {
      openId: user.openId,
      gameSession,
      rankType: tabKey === 'total' ? '2' : '1'
    };
    axios
      .get(`${baseUrl}/sys/umsExplodeOrder/getExplodeOrderDetailByGameSession`, { params })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200) {
          const {
            orderDetails: { orderPicVos }
          } = data;
          const url = orderPicVos[0]?.url;
          if (url) {
            setSrc(url);
            setPreviewShow(true);
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: '用户当日未提交报单数据！'
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const tabChange = (val: string) => {
    setTabKey(val);
  };

  const getData = () => {
    if (!openId) {
      return;
    }
    countRef.current++;
    const count = countRef.current;
    setUsers([]);
    setIsEmpty(false);
    setLoading(true);
    const params = {
      type: tabKey === 'today' ? 1 : 2,
      groupType: group,
      openId
    };
    axios
      .get(`${baseUrl}/sys/umsExplodeOrder/rankList`, { params })
      .then((res: any) => {
        if (count !== countRef.current) return;
        const { code, data } = res?.data || {};
        if (code === 200) {
          setUsers(data);
          if (!data.length) {
            setIsEmpty(true);
          } else {
            setShow(true);
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: '暂无排名数据'
          });
          onClose();
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scrollIntoView = () => {
    if (group === groupType) {
      scroll();
    } else {
      groupChange(groupType);
    }
  };

  const scroll = () => {
    setTimeout(() => {
      const list = document.querySelectorAll('.content .adm-list-item');
      list[curIdx]?.scrollIntoView();
    }, 0);
  };

  useEffect(() => {
    getData();
  }, [tabKey, group, openId]);
  useEffect(() => {
    groupType && groupChange(groupType);
  }, [groupType]);
  useEffect(() => {
    scroll();
  }, [curIdx]);

  if (!show && !isRank) return null;

  return (
    <div className="order-list">
      <div className="title">
        <Tabs onChange={groupChange} activeKey={group}>
          {groupTypeOption.map(item => {
            const { label, value } = item;
            return <Tabs.Tab title={label} key={value} />;
          })}
        </Tabs>
      </div>
      <Tabs onChange={tabChange} activeKey={tabKey}>
        <Tabs.Tab title="今日收益排名" key="today" />
        <Tabs.Tab title="总计收益排名" key="total" />
      </Tabs>
      <div className="desc">
        点击查看报单详情
        <Button size="small" color="primary" fill="none" onClick={scrollIntoView}>
          定位到自己
        </Button>
        <Popover
          content={
            <div>
              今日收益排名，查看持仓详情显示今天的持仓，总收益排名，查看持仓详情（3点前显示前一天，3点后显示今天）
              <br />
              如定位到自己功能无法使用，可能是因为提交的金额数据有问题，可联系复利小妹排查
            </div>
          }
          placement="bottom-start"
          mode="dark"
          trigger="click"
          defaultVisible={false}
        >
          <InformationCircleOutline />
        </Popover>
      </div>
      <List className="list-title">
        <List.Item onClick={() => {}}>
          <div className="row">
            <div className="idx">排名</div>
            <div className="name">参赛名</div>
            <div className="num">总资产</div>
            <div className="num">{tabKey === 'today' ? '今日收益' : '总收益'}</div>
          </div>
        </List.Item>
      </List>
      <div className="content">
        <List className="order-list-item">
          {users.map((user, i) => (
            <List.Item
              key={user.orderId}
              onClick={() => {
                checkAuthority(user);
              }}
            >
              <div className={user.openId === openId ? 'row red' : 'row'}>
                <div className="idx">{i + 1}</div>
                <div className="name">{user.gameName}</div>
                <div className="num">{user.totalAssets}</div>
                <div className="num">{tabKey === 'today' ? user.todayEarningRate : user.totalEarningRate}%</div>
              </div>
            </List.Item>
          ))}
        </List>
        {isEmpty && (
          <div className="empty">
            <Empty imageStyle={{ width: 80 }} description="暂无排名" />
          </div>
        )}
        {loading && (
          <div className="loading">
            <SpinLoading color="primary" style={{ '--size': '32px' }} />
          </div>
        )}
      </div>
      {!isRank && (
        <div className="footer">
          <Button color="danger" onClick={onClose}>
            重新提交
          </Button>
        </div>
      )}
      <ImageViewer
        classNames={{
          mask: 'customize-mask',
          body: 'customize-body'
        }}
        image={imageSrc}
        visible={previewShow}
        onClose={() => {
          setPreviewShow(false);
        }}
      />
    </div>
  );
}

export default OrderList;
