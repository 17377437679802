/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useRef, useState } from 'react';
import { Button, ImageViewer, Input, Radio, Space, SpinLoading, Toast } from 'antd-mobile';
import axios from 'axios';

import './index.scss';

import accountImg from '../assets/account.jpg';
import codeImg from '../assets/code.jpg';
import { baseUrl, groupTypeOption } from '../utils';
type SignType = {
  appId: string;
  timeStamp: string;
  nonceStr: string;
  signType: string;
  paySign: string;
};

const text = '获取验证码';
const gameSession = '4';

function Pay() {
  const [openId, setOpenId] = useState('');
  const [payOpenId, setPayOpenId] = useState('');
  const [status, setStatus] = useState(false);
  const [isPrePay, setPrePay] = useState(false);
  const [gameName, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [groupType, setGroup] = useState('');
  const [code, setCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [btnText, setText] = useState(text);
  const [disabled, setDisabled] = useState(false);
  const [showLoading, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageType, setImageType] = useState('code');
  const timeRef = useRef(60);
  const payPhoneRef = useRef('');

  const prepayIdRef = useRef('');

  const setCodeText = () => {
    setTimeout(() => {
      if (timeRef.current > 0) {
        setText(`${timeRef.current--}s重新获取`);
        setCodeText();
      } else {
        setText(text);
        setDisabled(false);
      }
    }, 1000);
  };

  const getCode = () => {
    const reg = /^1\d{10}$/;
    const test = reg.test(phone);
    if (!test) {
      Toast.show('请输入正确手机号');
      return;
    }
    // 验证是否需要支付
    axios
      .get(`${baseUrl}/sys/umsUserQualifications/checkUserQualification`, {
        params: { phone, gameSession }
      })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200 && data === 'NO_NEED_PAY') {
          payPhoneRef.current = phone;
        } else {
          payPhoneRef.current = '';
        }
      })
      .catch(() => {
        payPhoneRef.current = '';
        console.log('获取验证码失败');
      });

    // 发送验证码
    axios
      .post(`${baseUrl}/sys/umsPayOrder/sendVerificationCode`, {
        gameSession,
        phone,
        openId: openId
      })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200 && data) {
          const { verificationCode } = data || {};
          setVerificationCode(phone + verificationCode);
        }
      })
      .catch(() => {
        console.log('获取验证码失败');
      });

    setDisabled(true);
    timeRef.current = 59;
    setText(`${timeRef.current}s重新获取`);
    setCodeText();
  };

  const submit = () => {
    const codeTrim = (code || '').trim();
    if (isPrePay) {
      getSign();
      return;
    }

    if (!groupType) {
      Toast.show({
        icon: 'fail',
        content: '请选择分组'
      });
      return;
    }
    if (!gameName) {
      Toast.show({
        icon: 'fail',
        content: '请输入参赛名'
      });
      return;
    }
    if (!phone) {
      Toast.show({
        icon: 'fail',
        content: '请输入手机号'
      });
      return;
    }
    if (!codeTrim) {
      Toast.show({
        icon: 'fail',
        content: '请输入验证码'
      });
      return;
    }
    if (verificationCode !== `${phone}${codeTrim}`) {
      Toast.show({
        icon: 'fail',
        content: '手机号与验证码不匹配'
      });
      return;
    }

    setShow(true);
    if (payPhoneRef.current && payPhoneRef.current === phone) {
      axios
        .post(`${baseUrl}/sys/umsUserQualifications/updateUserData`, {
          gameName,
          groupType,
          openId: openId,
          phone: payPhoneRef.current,
          verificationCode: codeTrim,
          gameSession
        })
        .then((res: any) => {
          const { code, message } = res?.data || {};
          if (code === 200) {
            setStatus(true);
          } else {
            Toast.show({
              icon: 'fail',
              content: message
            });
          }
        })
        .catch(() => {
          Toast.show({
            icon: 'fail',
            content: '网络错误！'
          });
        })
        .finally(() => {
          setShow(false);
        });
      return;
    }

    axios
      .post(`${baseUrl}/wechat/pay/getPrePayId`, {
        gameName,
        groupType,
        openId: payOpenId,
        unionId: openId,
        phone,
        verificationCode,
        gameSession
      })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200 && data) {
          const { pre_pay_id } = data;
          prepayIdRef.current = pre_pay_id;
          getSign();
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
      })
      .finally(() => {
        setShow(false);
      });
  };

  const getSign = () => {
    axios
      .get(`${baseUrl}/wechat/pay/getSingCode`, { params: { prepay_id: prepayIdRef.current } })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200 && data) {
          pay(data);
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
      })
      .finally(() => {
        setShow(false);
      });
  };

  const pay = (data: SignType) => {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        ...data,
        package: `prepay_id=${prepayIdRef.current}`
      },
      (res: any) => {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          getAndUpdateOrderStatus();
          setStatus(true);
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        }
      }
    );
  };

  const getOrderStatus = () => {
    axios
      .get(`${baseUrl}/wechat/pay/getOrderStatus`, { params: { openId: openId, gameSession } })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200) {
          setPrePay(!!data);
          if (data) {
            const { game_name, group_type, phone, order_status, pre_pay_id } = data;
            setGroup(group_type);
            setName(game_name);
            setPhone(phone);
            prepayIdRef.current = pre_pay_id;
            setStatus(order_status === 'SUCCESS');
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(() => {
        Toast.show({
          icon: 'fail',
          content: '网络错误！'
        });
      })
      .finally(() => {
        setShow(false);
      });
  };
  const getAndUpdateOrderStatus = () => {
    axios
      .get(`${baseUrl}/wechat/pay/getAndUpdateOrderStatus`, {
        params: { prepayId: prepayIdRef.current }
      })
      .catch(e => console.log(e));
  };

  const image = imageType === 'code' ? codeImg : accountImg;

  useEffect(() => {
    if (openId) {
      getOrderStatus();
    }
  }, [openId]);

  useEffect(() => {
    const paramsStr = window.location.search;
    const params = new URLSearchParams(paramsStr);
    const code = params.get('code');
    if (!code) {
      setShow(false);
      Toast.show({
        icon: 'fail',
        content: '微信登录失败，请退出重试！'
      });
      return;
    }
    axios
      .get(`${baseUrl}/wechat/oauth2/access_token`, { params: { code, gameSession } })
      .then((res: any) => {
        const { code, data, message } = res?.data || {};
        if (code === 200 && data) {
          const { unionid, openid } = data || {};
          if (unionid) {
            setOpenId(unionid);
            setPayOpenId(openid);
          } else {
            Toast.show({
              icon: 'fail',
              content: message
            });
          }
        } else {
          setShow(false);
          Toast.show({
            icon: 'fail',
            content: message || '获取微信登录信息失败,请退出重试！'
          });
        }
      })
      .catch(() => {
        setShow(false);
        Toast.show({
          icon: 'fail',
          content: '获取微信信息网络异常！'
        });
      });
  }, []);

  return (
    <div className="pay">
      <div className="row">
        <div className="title">比赛名称</div>
        <div className="item">复利杯第四届比赛报名</div>
      </div>
      <div className="row" style={{ height: 'auto', marginBottom: 0 }}>
        <div className="title">比赛分组</div>
        <div className="item">
          <Radio.Group
            disabled={isPrePay || status}
            value={groupType}
            onChange={val => {
              setGroup(val as string);
            }}
          >
            <Space direction="vertical">
              {groupTypeOption.map(item => (
                <Radio value={item.value} key={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </div>
      <div className="row">
        <div className="title">参赛名</div>
        <div className="item">
          <Input
            disabled={isPrePay || status}
            className="input"
            placeholder="请输入参赛名"
            value={gameName}
            maxLength={15}
            onChange={val => {
              setName(val);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="title">手机号</div>
        <div className="item">
          <Input
            className="input"
            placeholder="请输入手机号"
            disabled={isPrePay || disabled || status}
            value={phone}
            type="tel"
            onChange={val => {
              setPhone(val);
            }}
            onBlur={() => {
              if (isNaN(+phone)) {
                setPhone('');
              }
            }}
          />
        </div>
      </div>
      {!isPrePay && !status && (
        <div className="row" style={{ flexWrap: 'wrap', height: 'auto' }}>
          <div className="title">验证码</div>
          <div className="code-content">
            <div className="item">
              <Input
                className="input"
                placeholder="验证码"
                type="tel"
                value={code}
                onChange={val => {
                  setCode(val);
                }}
              />
            </div>
            <Button
              color={disabled ? 'default' : 'danger'}
              fill="outline"
              className="code"
              onClick={getCode}
              disabled={disabled}
            >
              {btnText}
            </Button>
          </div>
        </div>
      )}
      <div className="info">
        <div className="title">费用说明</div>
        <div className="item">报名费 98元</div>
        {!status && <div className="desc">其他平台已付费用户，点击报名后不会再收费</div>}
      </div>

      <div className="flex">
        {!status && (
          <Button
            className="btn"
            size="large"
            color="danger"
            onClick={() => {
              submit();
            }}
          >
            提交报名
          </Button>
        )}
        {status && (
          <Button className="btn" size="large" color="success">
            已报名
          </Button>
        )}
      </div>
      <div className="bottom">
        <Button
          color="primary"
          fill="none"
          onClick={() => {
            setVisible(true);
            setImageType('account');
          }}
        >
          开户
        </Button>
        {status && (
          <Button
            color="primary"
            fill="none"
            onClick={() => {
              setVisible(true);
              setImageType('code');
            }}
          >
            进入比赛群
          </Button>
        )}
        {status && (
          <Button
            color="primary"
            fill="none"
            onClick={() => {
              location.href = 'https://mp.weixin.qq.com/s/kwDnsT7y7GHgq9sDhCs-1A';
            }}
          >
            比赛须知
          </Button>
        )}
      </div>
      <ImageViewer
        classNames={{
          mask: 'customize-mask',
          body: 'customize-body'
        }}
        image={image}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      />

      {showLoading && (
        <div className="loading">
          <SpinLoading color="primary" />
        </div>
      )}
    </div>
  );
}

export default Pay;
