/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';

import './index.scss';
function Home() {
  return (
    <div className="home">
      <div className="main">
        <img src="https://img.tukuppt.com/photo-big/00/10/95/6195bf39bc42d1752.jpg!/fh/350" alt="" />
      </div>
      <div className="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">
          <span className="desc">浙ICP备2024086581号</span>
        </a>
      </div>
    </div>
  );
}

export default Home;
