/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, ImageUploader, ImageViewer, Input, SafeArea, Space, Toast } from 'antd-mobile';
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader';
import { AddOutline, CloseCircleOutline } from 'antd-mobile-icons';
import axios from 'axios';
import dayjs from 'dayjs';

import './index.scss';

import { baseUrl, groupTypeMap, trueIdentifyOption } from '../utils';
import Capital from './Capital';
import NoPay from './NoPay';
import OrderList from './OrderList';

type Stock = {
  id?: number;
  ts?: number;
  orderId?: string;
  stockName: string;
  holdPrice: string;
  profitAndLossPrice: string;
  type?: string;
};

const gameSession = '4';
const isRank = location.href.includes('rank=1');
function Task() {
  const [show, setShow] = useState(true);
  const [isPay, setIsPay] = useState(true);
  const [canPreview, setCanPreview] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
  const [totalAssets, setTotal] = useState<string>('');
  const [stockList, setStockList] = useState<Stock[]>([]);
  const [isAnalysis, setIsAnalysis] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isTrueIdentify, setIsTrueIdentify] = useState<number | string>('');
  const [gameName, setGameName] = useState('');
  const [groupType, setGroupType] = useState('');

  const [nickname, setNickname] = useState('');
  const [openId, setOpenId] = useState('');
  const [payOpenId, setPayOpenId] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [previewShow, setPreviewShow] = useState(false);
  const [showList, setShowList] = useState(false);
  const lastUrl = useRef('');
  const lastAnalysis = useRef<{ holdStockVos: Stock[]; totalAssets: string }>({ totalAssets: '', holdStockVos: [] });
  const orderDetailsRef = useRef({});
  const noRankRef = useRef(false);

  const uploadImg = async (file: File): Promise<ImageUploadItem> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res: any = await axios.post(`${baseUrl}/common/uploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const { data = '', code } = res.data || {};
      if (code === 200) {
        return {
          url: data
        };
      } else {
        throw new Error('Fail to upload');
      }
    } catch (e) {
      throw new Error('Fail to upload');
    }
  };

  const analysis = async (item: ImageUploadItem) => {
    if (loading) return;
    if (item.url !== lastUrl.current) {
      setLoading(true);
      try {
        const res = await axios.post(`${baseUrl}/common/imageIdentify`, fileList[0]);
        const { data = {}, code } = res.data || {};
        setLoading(false);
        if (code === 200) {
          lastUrl.current = item.url;
          const ts = new Date().getTime();
          const { totalAssets } = data;
          const holdStockVos: Stock[] = data.holdStockVos || [];
          holdStockVos?.forEach((item, i) => {
            item.type = getType(item.profitAndLossPrice);
            item.holdPrice = item.holdPrice || '';
            item.profitAndLossPrice = item.profitAndLossPrice || '';
            item.stockName = item.stockName || '';
            item.ts = ts + i;
          });
          // 没识别出数据情况
          if (totalAssets === null) {
            Toast.show({
              icon: 'fail',
              content: '识别出错！'
            });
            return;
          }
          setTotal(totalAssets);
          setStockList(JSON.parse(JSON.stringify(holdStockVos)));
          lastAnalysis.current = {
            totalAssets,
            holdStockVos
          };
          Toast.show({
            icon: 'success',
            content: '识别完成！'
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: '识别出错！'
          });
        }
      } catch (e) {
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: '识别出错！'
        });
      }
      setIsAnalysis(1);
    } else {
      setLoading(true);
      setTimeout(() => {
        setStockList(JSON.parse(JSON.stringify(lastAnalysis.current.holdStockVos)));
        setTotal(lastAnalysis.current.totalAssets);
        Toast.show({
          icon: 'success',
          content: '识别完成！'
        });
        setLoading(false);
      }, 500);
    }
  };
  const delItem = (idx: number) => {
    const list = [...fileList];
    list.splice(idx, 1);
    setFileList(list);
  };
  const delStock = (idx: number) => {
    const list = [...stockList];
    list.splice(idx, 1);
    setStockList(list);
  };
  const getType = (val: string) => {
    let type = '';
    const num = +val;
    if (!isNaN(num)) {
      if (num > 0) {
        type = 'add';
      }
      if (num < 0) {
        type = 'minus';
      }
    }
    return type;
  };

  const dealExplodeOrder = () => {
    const data = {
      ...orderDetailsRef.current,
      holdStockVos: stockList,
      nickname,
      openId,
      orderPicVos: fileList,
      picType: '1',
      totalAssets,
      isTrueIdentify,
      gameName,
      groupType,
      identifyType: isAnalysis,
      checkStatus: 0,
      isFront: 1,
      gameSession
    };
    return axios
      .post(`${baseUrl}/sys/umsExplodeOrder/dealExplodeOrder`, data)
      .then((res: any) => {
        const { code, message, data } = res?.data || {};
        if (code === 200) {
          const { resultFlag, message } = data || {};
          if (!resultFlag && message) {
            Toast.show({
              icon: 'fail',
              content: message || '提交失败，请稍后重试'
            });
            return;
          }
          noRankRef.current = message;
          Toast.show({
            icon: 'success',
            duration: message ? 4000 : 2000,
            content: `提交成功。${message || ''}`
          });
          getData();
        } else {
          Toast.show({
            icon: 'fail',
            content: message
          });
        }
      })
      .catch(e => {
        Toast.show({
          icon: 'fail',
          content: '保存出错，请稍后重试！'
        });
        console.log(e);
      });
  };

  const submit = () => {
    if (!gameName) {
      Toast.show({
        icon: 'fail',
        content: '请输入参赛名'
      });
      return;
    }
    if (!groupType) {
      Toast.show({
        icon: 'fail',
        content: '请选择比赛分组'
      });
      return;
    }
    if (!fileList.length) {
      Toast.show({
        icon: 'fail',
        content: '请先上传账户图片'
      });
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dealExplodeOrder();
  };

  const getData = () => {
    const params = {
      holdDate: dayjs().format('YYYY-MM-DD'),
      openId,
      gameSession
    };
    axios
      .get(`${baseUrl}/sys/umsExplodeOrder/getExplodeOrderDetail`, { params })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200) {
          const { orderDetails = {} } = data || {};
          const { holdStockVos, orderPicVos, totalAssets, isTrueIdentify, orderId } = orderDetails;
          setFileList(orderPicVos);
          if (orderPicVos[0]?.url && !noRankRef.current) {
            setShowList(true);
          }
          setTotal(totalAssets);
          setIsTrueIdentify(isTrueIdentify);
          holdStockVos.forEach((item: Stock) => {
            item.type = getType(item.profitAndLossPrice);
          });
          setStockList(holdStockVos);
          setOrderId(orderId);
          orderDetailsRef.current = JSON.parse(JSON.stringify(orderDetails));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const showPreview = (url: string) => {
    setPreviewShow(true);
    setImageSrc(url);
  };

  useEffect(() => {
    if (isRank) return;
    if (openId) {
      getData();
    }
  }, [openId]);
  useEffect(() => {
    const paramsStr = window.location.search;
    const params = new URLSearchParams(paramsStr);
    const code = params.get('code');
    if (!code) {
      setShow(false);
      Toast.show({
        icon: 'fail',
        content: '微信登录失败，请退出重试！'
      });
      return;
    }
    axios
      .get(`${baseUrl}/wechat/oauth2/access_token`, { params: { code, gameSession } })
      .then((res: any) => {
        const { code, data } = res?.data || {};
        if (code === 200 && data) {
          const { unionid, nickname, gameName: name, gameType: group, roleType, openid } = data || {};
          if (unionid) {
            setOpenId(unionid);
            setPayOpenId(openid);
          }
          if (nickname) {
            setNickname(nickname);
          }
          if (name) {
            setGameName(name);
          }
          if (group) {
            setGroupType(group);
          }
          setIsPay(roleType === '1');
          // 1 报名 2 可预览
          setCanPreview(roleType === '1' || roleType === '2');
        } else {
          setShow(false);
          Toast.show({
            icon: 'fail',
            content: '获取微信登录信息失败,请退出重试！'
          });
        }
      })
      .catch(() => {
        setShow(false);
        Toast.show({
          icon: 'fail',
          content: '获取微信信息网络异常！'
        });
      });
  }, []);

  if (isRank)
    return (
      <OrderList
        canPreview={canPreview}
        openId={openId}
        groupType={groupType}
        payOpenId={payOpenId}
        onClose={() => setShowList(false)}
        updateCanPreview={() => setCanPreview(true)}
      />
    );

  return (
    <>
      {show && (
        <div className="task">
          <div className="desc">
            <span className="title">持仓日期 {dayjs().format('YYYY-MM-DD')}</span> 交作业时间：15:00-16:00
          </div>
          <div>
            <Button
              color="danger"
              fill="none"
              onClick={() => setVisible(true)}
              style={{ padding: 0, marginBottom: 16, textDecoration: 'underline' }}
            >
              今日有银证转账？先在此申报，再来交作业
            </Button>
          </div>
          <div className="desc">
            <span className="title">账户截图</span> 必须包括;总资产-股票名-持股金额，禁止打码
          </div>
          <div className="desc">
            <span className="title">上传截图</span>
            <div className="content">
              <ImageUploader
                style={{ '--cell-size': '140px' }}
                value={fileList}
                onChange={setFileList}
                upload={uploadImg}
              />
              <div className="abs-content">
                {fileList.map((item, idx) => {
                  return (
                    <div className="img-cover" key={item.url} onClick={() => showPreview(item.url)}>
                      <div
                        className="del"
                        onClick={e => {
                          e.stopPropagation();
                          delItem(idx);
                        }}
                      ></div>
                      {idx === 0 && (
                        <Button
                          loading={loading}
                          className="analysis"
                          color="danger"
                          onClick={e => {
                            e.stopPropagation();
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            analysis(item);
                          }}
                        >
                          识别
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>注意：用手机同花顺app截图，可提高准确率</div>
          </div>
          <div className="desc">
            <span className="title">今日总资产</span> 融资账户填净资产
            <div className="total-content">
              <div className="input border bold">
                <Input
                  style={{ height: '100%' }}
                  placeholder="请输入金额"
                  value={totalAssets}
                  onChange={val => {
                    setTotal(val);
                  }}
                  onBlur={() => {
                    if (isNaN(+totalAssets)) {
                      setTotal('');
                    }
                  }}
                />
              </div>
              <div className="unit">元</div>
            </div>
          </div>
          <div className="desc">
            <span className="title">今日持股</span> 空仓不填即可
            <div className="stock-row">
              <div className="input pl">股票名</div>
              <div className="input pl">持仓(元)</div>
              {/* <div className="input pl">盈亏(元)</div> */}
              <div className="icon"></div>
            </div>
            {stockList.map((stock, i) => {
              const { stockName, holdPrice, id = '', ts = '' } = stock;
              const key = `${id}${ts}`;
              return (
                <div className="stock-row" key={key}>
                  <div className="input border">
                    <Input
                      style={{ height: '100%' }}
                      placeholder="股票名称"
                      value={stockName}
                      onChange={val => {
                        const list = [...stockList];
                        list[i].stockName = val;
                        setStockList(list);
                      }}
                    />
                  </div>
                  <div className="input border bold">
                    <Input
                      style={{ height: '100%' }}
                      placeholder="持仓(元)"
                      value={holdPrice}
                      onChange={val => {
                        const list = [...stockList];
                        list[i].holdPrice = val;
                        setStockList(list);
                      }}
                      onBlur={() => {
                        const list = [...stockList];
                        if (isNaN(+list[i].holdPrice)) {
                          list[i].holdPrice = '';
                          setStockList(list);
                        }
                      }}
                    />
                  </div>
                  <div
                    className="icon"
                    onClick={() => {
                      delStock(i);
                    }}
                  >
                    <CloseCircleOutline />
                  </div>
                </div>
              );
            })}
            <div style={{ marginTop: 10 }}>
              <Button
                onClick={() => {
                  const list = [...stockList];
                  list.push({
                    ts: new Date().getTime(),
                    stockName: '',
                    holdPrice: '',
                    profitAndLossPrice: ''
                  });
                  setStockList(list);
                }}
              >
                <Space>
                  <AddOutline />
                  <span>添加持股</span>
                </Space>
              </Button>
            </div>
          </div>
          <div className="desc">
            <span className="title">识别数据是否正确？</span>
            <div>
              <Space direction="vertical" style={{ marginTop: 6 }}>
                {trueIdentifyOption.map(item => {
                  const { label, value } = item;
                  return (
                    <Checkbox
                      key={value}
                      checked={isTrueIdentify === value}
                      onChange={v => {
                        if (!v) {
                          setIsTrueIdentify('');
                        } else {
                          setIsTrueIdentify(value);
                        }
                      }}
                    >
                      {label}
                    </Checkbox>
                  );
                })}
              </Space>
            </div>
          </div>
          <div className="desc flex align-items" style={{ marginTop: 20 }}>
            <span className="title">参赛名: {gameName}</span>
          </div>
          <div className="desc">
            <span className="title">比赛分组: {groupTypeMap[groupType] || ''}</span>
          </div>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button
              size="large"
              color="danger"
              fill="outline"
              onClick={() => {
                submit();
              }}
            >
              提交作业
            </Button>
            <ImageViewer
              classNames={{
                mask: 'customize-mask',
                body: 'customize-body'
              }}
              image={imageSrc}
              visible={previewShow}
              onClose={() => {
                setPreviewShow(false);
              }}
            />
          </div>
          <div>
            <SafeArea position="bottom" />
          </div>
        </div>
      )}
      {!show && <div className="empty">登录信息获取失败，请退出重试</div>}
      {visible && (
        <Capital openId={openId} orderId={orderId} nickname={nickname} setVisible={setVisible} getData={getData} />
      )}
      {!isPay && <NoPay />}
      {showList && (
        <OrderList
          openId={openId}
          payOpenId={payOpenId}
          canPreview={canPreview}
          groupType={groupType}
          onClose={() => setShowList(false)}
          updateCanPreview={() => setCanPreview(true)}
        />
      )}
    </>
  );
}

export default Task;
