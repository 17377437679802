import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import Pay from './pay/index';
import Task from './task/index';
import Home from './home/index';

function App() {
  return (
    <div className="app">
      <div className="space"></div>
      <div className="overflow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/order" element={<Task />} />
          <Route path="/pay" element={<Pay />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
