const groupTypeOption = [
  { label: '1-10万', value: 102 },
  { label: '10万以上', value: 101 }
  // { label: '10-50万', value: 101 },
  // { label: '50万以上', value: 100 }
];

const groupTypeMap: Record<string, string> = {};
groupTypeOption.forEach(item => {
  const { label, value } = item;
  groupTypeMap[value] = label;
});

const trueIdentifyOption = [
  { label: '正确', value: 1 },
  { label: '不正确，请小妹手动修改登记', value: 0 }
];

// 线上数据
const baseUrl = 'http://120.26.110.189:8080';
// 测试库
// const baseUrl = 'http://47.99.85.46:8080';

export { groupTypeOption, groupTypeMap, trueIdentifyOption, baseUrl };
